/* 
 * 常用方法封装
 *
 */
import router from '../router'
import $HiMessage from '@/components/hi-messages/HiMessage.js' // 导入提示框

export default {

	/* *
	 * 成功提示
	 */
	isSucc(text = '操作成功！') {
		$HiMessage({
			type: 'success',
			text: text
		})
	},

	isErr(text = '操作失败！') {
		$HiMessage({
			type: 'error',
			text: text
		})
	},
	// 警告
	isWarn(text = '操作失败！') {
		$HiMessage({
			type: 'warn',
			text: text
		})
	},

	// 延迟跳转页面
	toPage(url = '', time = 0) {
		setTimeout(function() {
			router.push(url)
		}, time)
	},
	// 替换 跳转 关闭当前页面
	toReplace(url = '', time = 0) {
		setTimeout(function() {
			router.push({
				path: url,
				replace: true
			})
		}, time)
	},

	// a 跳转链接
	toHref(url = '', time = 0) {
		setTimeout(function() {
			location.href = url
		}, time)
	},

	// 新窗口跳出
	toBlank(url) {
		window.open(url)
	},

	// 拨打电话
	toTel(phone) {
		location.href = "tel:" + phone;
	},


	/* 
	 * 字符串 判断是否为空
	 */
	isNull(str0) {
		if (str0 == '') return true
		if (str0 == null) return true
		if (str0 == undefined) return true

		var str = str0.replace(/^\s+|\s+$/gm, '')
		if (str == '') return true
		if (str == null) return true
		if (str == undefined) return true
		return false
	},

	/* 
	 * 字符串返回某一字符 最后一次出现后面的值
	 * str：字符串
	 * j：截取字符
	 */
	strLastSub(str, j = '') {
		return str.split(j).pop()
	},

	// 节流方法
	throttle(func, delay) {
		let timer = null
		return function() {
			if (!timer) {
				timer = setTimeout(() => {
					func.apply(this, arguments)
					timer = null
				}, delay)
			}
		}
	},

	// 触底函数 调用
	onBottom() {
		//在页面上返回内容的可视高度
		var clientHeight = document.documentElement.clientHeight;
		//滚动条的位置
		let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		//返回整个元素的高度（包括带滚动条的隐蔽的地方）滚动条高度
		var scrollHeight = document.documentElement.scrollHeight;
		//是否滚动到底部的判断
		if (Math.round(scrollTop) + clientHeight >= scrollHeight) {
			console.log('触底了===')
			// 执行方法
		}
	},



	/* ** 功能方法 **************************************************************************************** */

	/*
	 * 判断是否为微信内打开
	 * 微信打开返回 1
	 */
	isWeixin() {
		// 验证是否微信内打开
		var ua = navigator.userAgent.toLowerCase();
		if (ua.match(/MicroMessenger/i) == "micromessenger") {
			return 1
		} else {
			return 0
		}
	},
	/* 
	 * 判断是否为手机打开
	 */
	isMobile() {
		if (/mobile/i.test(navigator.userAgent)) {
			return 1 // 手机打开
		} else {
			return 0
		}
	},
	
	/* 
	 * 获取当前 地址
	 */
	getUrl(path = '') {
		var url = window.location.href
		if (path != '') {
			url = window.location.origin + path
		}
		return url
	},
	
	// 触底了
	onReachBottom() {
		window.addEventListener('scroll', this.onBottom, true)
	},
	
	/** 项目数据缓存与读取 s ------------------------------------------------------ */
	
	/* 
	 * 缓存前缀
	 */
	getPre() {
		// 获取当前页面的域名（主机名）
		return window.location.hostname + '_';
	},
	
	/* 
	 * 添加缓存
	 */
	setCache(key, val) {
		var $key = this.getPre() + key
		if (typeof val !== 'string') val = JSON.stringify(val)
		sessionStorage.setItem($key, val)
		return true;
	},
	// 获取缓存
	getCache(key) {
		var $key = this.getPre() + key
		var val = sessionStorage.getItem($key)
		if (typeof val === 'string' && val.startsWith('{') && val.endsWith('}')) {
			val = JSON.parse(val)
		}
		return val
	},
	
	/* 
	 * 缓存短信记录随机值 code
	 */
	setSmsCode(code='') {
		const key = this.getPre() + 'sms_show_code'
		sessionStorage.setItem(key, code)
		return true;
	},
	// 读取短信记录随机值 
	getSmsCode() {
		const key = this.getPre() + 'sms_show_code'
		return sessionStorage.getItem(key)
	},
	
	/* 
	 * 缓存 IP 黑名单状态 
	 */
	setHeimingdanIp() {
		const key = this.getPre() + 'heimingdan_ip_state'
		sessionStorage.setItem(key, true);
		return true
	},
	// 获取黑名单状态
	getHeimingdanIp() {
		const key = this.getPre() + 'heimingdan_ip_state'
		return sessionStorage.getItem(key)
	},
	/** 项目数据缓存与读取 e ====================================================== */

}
<template>
	<!-- 顶部间隔 -->
	<div class="page-top-gap"></div>
	<div class="hi-page-box-li head-top-logo">
		<img src="@/assets/imgs/index/logo.png" alt="" />
	</div>

</template>

<script>
	export default {
		name: 'AppHeader',
		props: {
			configs: {
				default: () => []
			}
		},
		data() {
			return {
				navs: [],   // 导航
				keyword: ''
			}
		},
		created() {
			// 导航
		},
		methods: {
			
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.head-top-logo{
		width: 60%; 
		margin-bottom: 1rem;
	}
</style>